import React from 'react';
import { GridColumn } from '@/blocks';
import { Col, Row } from 'react-bootstrap';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';

const gridGutterClasses = 'gx-2 gy-3 g-sm-3 g-xl-4 pb-2 my-2';
const gridDenseGutterClasses = 'px-0 px-sm-1 px-xl-2 g-2 pb-2 my-2';

const gridStyles = {
    width: '100%',
    overflow: 'hidden',
    borderRadius: '1rem',
};

const gridThemes = {
    white: {
        backgroundColor: 'white',
    },
    whitetobeige: {
        backgroundImage: 'var(--rr-gradient-white-beige)',
    },
    beige: {
        backgroundColor: 'var(--rr-color-beige)',
    },
    turquoise: {
        backgroundColor: 'var(--rr-color-turquoise)',
    },
    dark: {
        color: 'white',
        backgroundColor: 'var(--rr-color-dark)',
    },
    green: {
        color: 'white',
        backgroundColor: 'var(--rr-color-green)',
    },
};

type GridProps = {
    grid: Queries.HygraphCMS_Grid;
    page: Queries.HygraphCMS_Page;
};

export const Grid = ({
    page,
    grid: {
        columnCount,
        alignItems,
        dense,
        narrow,
        theme,
        columnWrapReverse,
        columns = [],
    },
}: GridProps) => {
    if (!columns.length) return null;

    const columnVariants = {
        xs: 1,
        sm: columnCount > 2 ? 2 : 1,
        md: columnCount === 2 ? 2 : columnCount,
        lg: columnCount,
    };

    return (
        <Row
            {...columnVariants}
            className={`${dense ? gridDenseGutterClasses : gridGutterClasses} ${
                columnWrapReverse && columnCount === 2
                    ? 'flex-wrap-reverse flex-md-wrap'
                    : ''
            }`}
            css={css({
                ...(theme ? gridThemes[theme] : {}),
                ...gridStyles,
                maxWidth: narrow ? '1000px' : 'var(--content-max-width)',
                alignItems: alignItems || 'center',
            })}
        >
            {columns.map((column) => {
                const { alignSelf, alignContent } = column;
                switch (column.component) {
                    case 'GridColumn':
                        const gridColumn =
                            column as Queries.HygraphCMS_GridColumn;
                        return (
                            <Col
                                key={column.id}
                                className="mt-2"
                                css={css({
                                    alignSelf: alignSelf || 'unset',
                                    textAlign: alignContent || 'left',
                                })}
                            >
                                <GridColumn
                                    gridColumn={gridColumn}
                                    page={page}
                                />
                            </Col>
                        );
                    default:
                        return null;
                }
            })}
        </Row>
    );
};

export const gridFragment = graphql`
    fragment HygraphCMS_Grid on HygraphCMS_Grid {
        id
        component
        alignItems
        columnCount
        columnWrapReverse
        dense
        narrow
        theme
        columns {
            ...HygraphCMS_GridColumn
        }
    }
`;
