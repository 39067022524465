import React from 'react';
import {
    Breadcrumb as BootBreadcrumb,
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/react';

const breadcrumbStyle = css({
    maxWidth: 'var(--content-max-width)',
});

export const Breadcrumb = ({
    navigationLabel,
    breadcrumb,
}: Queries.HygraphCMS_Page) => {
    return (
        <Container fluid css={breadcrumbStyle}>
            <Row className="px-1 px-sm-2">
                <Col>
                    <BootBreadcrumb>
                        {breadcrumb?.parents?.map((parent) => (
                            <li key={parent.id} className="breadcrumb-item">
                                <Link to={`/${parent.slug}`}>
                                    {parent.navigationLabel}
                                </Link>
                            </li>
                        ))}
                        <li className="breadcrumb-item active">
                            {navigationLabel}
                        </li>
                    </BootBreadcrumb>
                </Col>
            </Row>
        </Container>
    );
};

export const breadcrumbFragment = graphql`
    fragment HygraphCMS_Breadcrumb on HygraphCMS_Breadcrumb {
        id
        parents {
            ... on HygraphCMS_Page {
                id
                locale
                slug
                navigationLabel
            }
        }
    }
`;
