import { useState } from 'react';
import Cookies, { CookieSetOptions } from 'universal-cookie';

const useCookie = (key: string) => {
    const cookies = new Cookies();

    const [item, setItemValue] = useState(() => {
        if (cookies.get(key)) {
            return cookies.get(key);
        }
        return null;
    });

    const setValue = (value: string, options?: CookieSetOptions) => {
        setItemValue(value);
        const d = new Date();
        d.setTime(d.getTime() + 360 * 24 * 60 * 60 * 1000);

        const defaultOptions = {
            expires: d,
            domain: '.roughlyright.com',
            path: '/',
        };

        cookies.set(key, value, options || defaultOptions);
    };

    const removeItem = () => {
        cookies.remove(key);
    };

    return [item, setValue, removeItem];
};

export default useCookie;
