import { css } from '@emotion/react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { RichTextContent } from '@graphcms/rich-text-types';
import { graphql } from 'gatsby';
import useCookie from 'hooks/useCookie';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const styles = css({
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    bottom: '-100%',
    left: 0,
    right: 0,
    padding: '3rem',
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0,0,0,0.8)',
    color: 'white',
    transition: 'bottom 0.5s ease-in-out',
    a: {
        color: 'white',
        textDecoration: 'underline',
    },
    '> div': {
        maxWidth: 'var(--content-max-width)',
    },
    '&.show': {
        bottom: 0,
    },
});

export const CookieBanner = ({
    cookieBanner,
}: {
    cookieBanner: Queries.HygraphCMS_CookieBanner;
}) => {
    const [show, setShow] = useState(false);
    const [cookie, setCookie] = useCookie('acceptedCookie');

    const acceptCookie = () => {
        const d = new Date();
        d.setTime(d.getTime() + 360 * 24 * 60 * 60 * 1000);
        setCookie('true', { expires: d });
        setShow(false);
    };

    useEffect(() => {
        setTimeout(() => {
            if (cookie !== 'true') {
                setShow(true);
            }
        }, 1000);
    }, []);

    return (
        <section css={styles} className={show ? 'show' : ''}>
            <div className="row">
                <div className="col">
                    <RichText
                        content={cookieBanner.text.raw as RichTextContent}
                    />
                </div>
                <div className="d-grid col-md-auto d-md-block">
                    <Button className={'px-3'} onClick={acceptCookie}>
                        {cookieBanner.buttonText}
                    </Button>
                </div>
            </div>
        </section>
    );
};

export const cookieBannerFragment = graphql`
    fragment HygraphCMS_CookieBanner on HygraphCMS_CookieBanner {
        id
        buttonText
        text {
            raw
        }
    }
`;
