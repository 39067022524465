import { graphql } from 'gatsby';
import React from 'react';
import greenCheck from '../../../images/green-check.svg';
import { css } from '@emotion/react';

export const Checklist = (checklist: Queries.HygraphCMS_Checklist) => {
    return (
        <ul className="list-unstyled">
            {checklist.items.map((item) => (
                <li
                    key={item.id}
                    className="d-flex align-items-center"
                    style={{
                        marginBottom: '4px',
                        fontSize: '0.8rem',
                    }}
                >
                    <img
                        src={greenCheck}
                        css={css({
                            width: '18px',
                            opacity: item.checked ? 1 : 0,
                            marginRight: '10px',
                        })}
                    ></img>
                    {item.text}
                </li>
            ))}
        </ul>
    );
};

export const headingFragment = graphql`
    fragment HygraphCMS_ChecklistItem on HygraphCMS_ChecklistItem {
        stage
        locale
        id
        text
        component
        checked
    }
    fragment HygraphCMS_Checklist on HygraphCMS_Checklist {
        id
        stage
        component
        items {
            ...HygraphCMS_ChecklistItem
        }
    }
`;
