import { graphql } from 'gatsby';
import React from 'react';
import greenCheck from '../../../images/green-check.svg';
import { css } from '@emotion/react';

export const FeatureChecklist = (
    featureChecklist: Queries.HygraphCMS_FeatureChecklist,
) => {
    return (
        <table
            css={css({
                width: '100%',
            })}
        >
            <thead>
                <tr>
                    <th>
                        <h5 className="mb-0">{featureChecklist.title}</h5>
                    </th>
                    <th className="text-center px-1">Basic</th>
                    <th className="text-center text-nowrap">Plus +</th>
                </tr>
            </thead>
            <tbody>
                {featureChecklist.items.map((item) => (
                    <tr
                        key={item.id}
                        css={css({
                            borderBottom: '1px solid #f7f7f7',
                            ':last-child': {
                                borderBottom: 'none',
                            },
                            ':hover': {
                                backgroundColor: '#f7f7f7',
                            },
                        })}
                    >
                        <td className="py-1">{item.text}</td>
                        <td className="text-center">
                            {item.priceLevels.includes('basic') && (
                                <img
                                    src={greenCheck}
                                    css={css({
                                        width: '18px',
                                        marginRight: '10px',
                                    })}
                                />
                            )}
                        </td>
                        <td className="text-center">
                            {item.priceLevels.includes('plus') && (
                                <img
                                    src={greenCheck}
                                    css={css({
                                        width: '18px',
                                        marginRight: '10px',
                                    })}
                                />
                            )}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export const headingFragment = graphql`
    fragment HygraphCMS_FeatureChecklistItem on HygraphCMS_FeatureChecklistItem {
        stage
        locale
        id
        text
        component
        priceLevels
    }
    fragment HygraphCMS_FeatureChecklist on HygraphCMS_FeatureChecklist {
        id
        stage
        title
        component
        items {
            ...HygraphCMS_FeatureChecklistItem
        }
    }
`;
