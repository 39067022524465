import React from 'react';
import { Image, Video } from '@/parts';
import { RichText } from '@graphcms/rich-text-react-renderer';
import {
    RichTextContent,
    EmbedProps,
    EmbedReferences,
} from '@graphcms/rich-text-types';
import { Container, Row, Col } from 'react-bootstrap';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';
import { ParagraphHeading } from '../richtext/ParagraphHeading';

const paragraphStyles = {
    ul: {
        paddingLeft: '1rem',
    },
    li: {
        marginBottom: '0.5rem',
    },
    img: {
        boxShadow: '0 2px 6px grey',
        marginBottom: '2.5rem',
        maxWidth: '700px',
        height: 'auto',
        width: '100%',
    },
};

type ArticleProps = {
    article: Queries.HygraphCMS_HelpArticle;
    page: Queries.HygraphCMS_Page;
    index: number;
};

const getHero = (hero: Queries.HygraphCMS_HelpArticlearticleHeroUnion) => {
    switch (hero.component) {
        case 'Image':
            const image = hero as Queries.HygraphCMS_Image;
            return <Image key={image.id} image={image} />;
        case 'Video':
            const video = hero as Queries.HygraphCMS_Video;
            return <Video key={video.id} {...video} />;
        default:
            return null;
    }
};

const getRowComponent = (
    row: Queries.HygraphCMS_GridColumnContentrowsUnion,
) => {
    switch (row.component) {
        case 'Image':
            const image = row as Queries.HygraphCMS_Image;
            return <Image key={image.id} image={image} />;
        case 'Video':
            const video = row as Queries.HygraphCMS_Video;
            return <Video key={video.id} {...video} />;
        default:
            return null;
    }
};

const Paragraph = (
    paragraph: Queries.HygraphCMS_HelpArticleArticleBodyRichText,
) => {
    return (
        <div css={css(paragraphStyles)}>
            <RichText
                content={paragraph.json as RichTextContent}
                references={paragraph?.references as unknown as EmbedReferences}
                renderers={{
                    h1: ({ children }) => (
                        <ParagraphHeading as="h1">{children}</ParagraphHeading>
                    ),
                    h2: ({ children }) => (
                        <ParagraphHeading as="h2">{children}</ParagraphHeading>
                    ),
                    h3: ({ children }) => (
                        <ParagraphHeading as="h3">{children}</ParagraphHeading>
                    ),
                    h4: ({ children }) => (
                        <ParagraphHeading as="h4">{children}</ParagraphHeading>
                    ),
                    h5: ({ children }) => (
                        <ParagraphHeading as="h5">{children}</ParagraphHeading>
                    ),
                    h6: ({ children }) => (
                        <ParagraphHeading as="h6">{children}</ParagraphHeading>
                    ),
                    img: ({ height, width, src, title, altText }) => (
                        <img
                            src={src}
                            alt={altText}
                            width={width}
                            height={height}
                            title={title}
                            loading="lazy"
                        />
                    ),
                    // Only the first row of GridColumnContent will be embedded, because the Embed can only return one JSX element, not an array.
                    embed: {
                        GridColumnContent: ({
                            rows,
                        }: EmbedProps<Queries.HygraphCMS_GridColumnContent>) => {
                            return (
                                <Row>
                                    <Col
                                        css={css({
                                            width: '100%',
                                            overflow: 'hidden',
                                            maxWidth: '730px',
                                            paddingBottom: '2.5rem',
                                            img: { marginBottom: '0rem' },
                                        })}
                                    >
                                        {getRowComponent(rows[0])}
                                    </Col>
                                </Row>
                            );
                        },
                    },
                }}
            />
        </div>
    );
};

export const HelpArticle = ({
    article: { articleTitle, articleSummary, articleHero, articleBody },
}: ArticleProps) => {
    if (!articleHero?.component) return null;

    return (
        <Container
            fluid
            as="section"
            className={`d-flex flex-column align-items-center`}
            css={css({
                position: 'relative',
                backgroundColor: 'white',
            })}
        >
            <Row
                xs={1}
                sm={1}
                md={2}
                className="gx-2 gy-3 g-sm-3 g-xl-4 mt-2"
                css={css({
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '1rem',
                    maxWidth: 'var(--content-max-width)',
                })}
            >
                <Col>
                    <div>
                        <h1>{articleTitle}</h1>
                        <div css={css(paragraphStyles)}>
                            <RichText
                                content={articleSummary?.raw as RichTextContent}
                            />
                        </div>
                    </div>
                </Col>
                <Col>{getHero(articleHero)}</Col>
            </Row>
            <Row
                sm={1}
                className="gx-2 gy-3 g-sm-3 g-xl-4 mt-2"
                css={css({
                    width: '100%',
                    overflow: 'hidden',
                    borderRadius: '1rem',
                    maxWidth: '1000px',
                })}
            >
                <Col>{Paragraph(articleBody)}</Col>
            </Row>
        </Container>
    );
};

export const helpArticleFragment = graphql`
    fragment HygraphCMS_HelpArticle on HygraphCMS_HelpArticle {
        id
        component
        articleTitle
        articleSummary {
            raw
        }
        articleHero {
            ...HygraphCMS_Image
            ...HygraphCMS_Video
        }
        articleBody {
            json
            references {
                ...HygraphCMS_GridColumnContent
            }
        }
    }
`;
