import { Grid } from '@/blocks';
import { Container } from 'react-bootstrap';
import { css } from '@emotion/react';
import { graphql } from 'gatsby';

const blockThemes = {
    white: {
        backgroundColor: 'white',
    },
    whitetobeige: {
        backgroundImage: 'var(--rr-gradient-white-beige)',
        backgroundRepeat: 'no-repeat',
    },
    beige: {
        backgroundColor: 'var(--rr-color-beige)',
    },
    turquoise: {
        backgroundColor: 'var(--rr-color-turquoise)',
    },
    dark: {
        color: 'white',
        backgroundColor: 'var(--rr-color-dark)',
    },
    green: {
        color: 'white',
        backgroundColor: 'var(--rr-color-green)',
    },
};

type BlockProps = {
    block: Queries.HygraphCMS_Block;
    page: Queries.HygraphCMS_Page;
    index: number;
};

export const Block = ({
    block: { theme, tag, grids = [], anchorId },
    page,
}: BlockProps) => {
    if (!grids.length) return null;

    return (
        <Container
            fluid
            as={tag}
            className={`d-flex flex-column align-items-center`}
            css={css({
                ...(theme && blockThemes[theme] && blockThemes[theme]),
                position: 'relative',
            })}
        >
            {anchorId && (
                <div
                    id={anchorId}
                    css={css({
                        visibility: 'hidden',
                        position: 'absolute',
                        top: '50px',
                    })}
                ></div>
            )}
            {grids.map((grid) => {
                switch (grid.component) {
                    case 'Grid':
                        const asGrid = grid as Queries.HygraphCMS_Grid;
                        return (
                            <Grid key={asGrid.id} page={page} grid={asGrid} />
                        );
                    default:
                        return null;
                }
            })}
        </Container>
    );
};

export const blockFragment = graphql`
    fragment HygraphCMS_Block on HygraphCMS_Block {
        id
        component
        tag
        theme
        anchorId
        grids {
            ...HygraphCMS_Grid
        }
    }
`;
