import { css } from '@emotion/react';
import { graphql, Link } from 'gatsby';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { ButtonLink, Logo } from '../blocks/parts/';

type NavigationProps = {
    page: Queries.HygraphCMS_Page;
};

const containerStyle = css({
    maxWidth: 'var(--content-max-width)',
});

const navStyle = css({
    'a.nav-link.active': {
        color: '#000',
        textDecoration: 'underline',
        textDecorationColor: 'var(--bs-primary)',
        textDecorationThickness: 4,
        textUnderlineOffset: 5,
    },
    'a.nav-link:hover:not(.active)': {
        textDecoration: 'underline',
        textDecorationColor: 'var(--bs-gray-400)',
        textDecorationThickness: 4,
        textUnderlineOffset: 5,
    },
    marginBottom: '2rem',
    '&&': {
        position: 'sticky',
        top: 0,
        backdropFilter: 'blur(8px)',
        zIndex: 1000,
        background: 'rgba(255,255,255,0.7)',
        overflow: 'hidden',
    },
});

const gridGutterClasses = 'gx-2 gy-3 gx-lg-3 gx-xl-4';

const isActivePage = (
    page: Queries.HygraphCMS_Page,
    navPage: Queries.HygraphCMS_Page,
) => {
    return (
        page.slug === navPage.slug ||
        page.breadcrumb?.parents.some((parent) => parent.slug === navPage.slug)
    );
};

export const Navigation = ({
    page,
    page: { navigation, locale },
}: NavigationProps) => {
    if (!navigation) {
        return null;
    }

    const { pages, actionButtons } = navigation;

    return (
        <>
            <Navbar key={'md'} expand={'md'} className="px-1" css={navStyle}>
                <Container
                    fluid
                    css={containerStyle}
                    className={gridGutterClasses}
                >
                    <Navbar.Brand as={Link} to={`/${locale}`}>
                        <Logo />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`}>
                        Menu
                    </Navbar.Toggle>
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-md`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-md`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton></Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="justify-content-end align-items-md-center flex-grow-1">
                                {pages.map((navPage) => (
                                    <Nav.Link
                                        className={`${
                                            isActivePage(page, navPage)
                                                ? 'active'
                                                : ''
                                        } py-md-0 text-uppercase`}
                                        key={navPage.id}
                                        as={Link}
                                        to={`/${navPage.slug}`}
                                    >
                                        {navPage.navigationLabel}
                                    </Nav.Link>
                                ))}
                                {actionButtons.map((button) => (
                                    <div
                                        key={button.id}
                                        className="px-md-1 py-1 py-md-0"
                                    >
                                        <ButtonLink {...button} />
                                    </div>
                                ))}
                            </Nav>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>
        </>
    );
};

export const navigationFragment = graphql`
    fragment HygraphCMS_Navigation on HygraphCMS_Navigation {
        id
        component
        actionButtons {
            ...HygraphCMS_ButtonLink
        }
        pages {
            ... on HygraphCMS_Page {
                id
                locale
                slug
                navigationLabel
            }
        }
    }
`;
